.synthese-info {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 20px;
    color: #252225;
    .info-value, .info-value-to-complete {
        margin-top: 8px;
        font-weight: 600;
    }
    .info-value-to-complete {
        color: #DB1428
    }
}

.corps-synthese {
    display: flex;
    .left-side {
        flex:1;
        padding-top: 16px;
    }
    .right-side {
        flex:1;
        padding-top: 16px;
        background: #F8F7F8
    }
}

@media only screen and (max-width: 952px) {
    .corps-synthese {
        flex-direction: column;
    }
}