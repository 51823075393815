.page-title {
  font-size: 16px;
  margin-left: 16px;
  font-weight: 600;
}

.tab-title {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-bottom: 1px solid $grey;
}

.tab-bg {
  background-color: white !important;
}

.home-bg {
  background-color: #f2e8ed !important;
}

.tab-complition {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-bottom: 1px solid $grey;
  background-color: $secondary;
}

.customGroupMenu-tab-default {
  display: none !important;
}

.customGroupMenu-tabVerticalStyled {
  display: inline-flex !important;
}

.customGroupMenu-tabMobile {
  height: 96px;
}

.customGroupMenu-tabMobile-margin {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.customGroupMenu-tabMobile-arrow {
  margin-left: 22px;
}

.card-content-group .customGroupMenu-tabMobile-arrow,
.rgpd-text .customGroupMenu-tabMobile-arrow{
  margin-left: 0;
}

.customGroupMenu-tabMobile-title {
  vertical-align: top;
  text-align: left;
  margin-left: 10px;
}

.customGroupMenu-completion-completed {
  color: $primary;
}

.customGroupMenu-completion-inprogress {
  color: #5a535a;
}

.vertical-tab {
  min-height: 56px !important;
  font-size: 16px !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
  text-transform: initial !important;
  opacity: 1 !important;
}

.vertical-tab-selected {
  color: $default !important;
  background-color: $selectedTab !important;
}

.vertical-tab-default {
  color: $default !important;
}

.vertical-tab-disabled {
  color: #a29aa2 !important;
  background-color: #f1eef1 !important;
}

.vertical-tab-arrow {
  right: 0 !important;
  position: absolute !important;
}

.vertical-tab-arrow-selected {
  transform: rotate(180deg) !important;
  color: $primary;
}

.tab-mobile-menu {
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
