.tabInfos{
    display:flex;
    width: 100%;
    font-size:16px;
}

.tabInfos-title{
    background-color: $secondary;
    height: 52px;
    line-height: 52px;
    margin-bottom: 52px;
    border-bottom: 1px solid;
    border-color: #D6D1D6;
}
.tabInfos-title-text{
    padding-left: 16px;
    padding-right: 16px;
    font-style: bold;
    font-weight: 600;
}


.tabInfos-informations{
    width: 50%;
}

.tabInfos-criterias{
    width: 50%;
    padding: 16px;
    background-color: #f8f7f8;
    border-left: 1px solid;
    border-color: #D6D1D6;
    border-bottom-right-radius: 8px; 
}

.tabInfos-criterias-helper{
    display:flex;
    padding-top: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 14px;
    border: 2px solid #0076A8;
    background-color: #E9F8FF;
    color: #0076A8;
}

.tabInfos-criterias-helper-icon{
    padding-left: 16px;
    padding-right: 8px;
}

.tabInfos-criterias-helper-text{
    padding-right: 16px;
    padding-bottom: 16px;
}

.tabInfos-criterias-helper-title{
    font-style: bold;
    font-weight: 600;
}

.tabInfos-criterias-helper-infos{
    font-style: normal;
    font-weight: 400;
    text-align: justify;
}

.tabInfos-information{
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
}

.tabInformation-informations-title{
    color: $default;
    font-style: normal;
    font-weight: 400;
}

.tabInformation-informations-value{
    font-style: bold;
    font-weight: 600;
}


@media only screen and (max-width: 952px) {

    .tabInfos{
        display: block !important;
        margin-top:0px;
    }

    .tabInfos-informations{
        width: 100% !important;
    }
    
    .tabInfos-criterias{
        width: 100% !important;
        border-left: hidden !important;
    }
}