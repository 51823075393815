  .tab-icons {
    color: #5a535a;
    opacity: 0.7;
    max-height: 32px;
  }

  .tab-icon-active {
    color: $default;
    opacity: 1;
    max-height: 32px;
  }
  
  .MuiTab-labelIcon {
    font-size: 16px !important;
    line-height: 1.25 !important;
    font-weight: 600 !important;
    text-transform: initial !important;
  }
  
  .Mui-selected-tab {
    border: 2px solid $primary !important;
    border-bottom: none !important;
    background-color: $selectedTab !important;
    color: $default !important;
    font-weight: 600 !important;
    align-content: center !important;
  }

  .Mui-not-slected-tab:first-child {
    border-left: 1px solid $grey!important;
  }
  
  .Mui-not-slected-tab {
    border-top: 1px solid $grey !important;
    border-right: 1px solid $grey !important;
    border-left: none !important;
    border-bottom: 2px solid $primary !important;
    color: #5a535a !important;
    font-weight : 400!important;
    align-content: center !important;
    opacity: 1 !important;
  }

  .tab-save-button {
    height: 48px !important;
    border-radius: 8px !important;
    width: calc(100% - 32px);
    margin-left: 16px !important;
    background-color: $primary !important;
    color: white !important;
    border: 2px solid $primary !important;
  }

  .tab-save-button:disabled{
    background-color: $disabledGreyBackground !important;
    color: $disabledGreyText !important;
    border: 2px solid $grey !important;
  }

  .tab-save-button-container{
    padding-bottom: 16px !important;
  }

  .completion-totale {
    padding: 32px 16px;

    .completion-totale-libelle {
      font-size: 18px;
      color: $default;
      line-height: 20px;
      font-weight: 600;
    }

    .completion-totale-progressbar {
      margin-top: 8px;
      border-radius: 8px;
      height: 8px;
    }
  }

  