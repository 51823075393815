@import "./color.scss";
@mixin rotate($angle) {
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -webkit-transform: rotate($angle);
    transform: rotate($angle);
  }
  
  @mixin arrow($arrowSize: 20px, $arrowBorderSize: 3px, $arrowBarWidth: 2px, $arrowBarHeight: 25px) {
    display: inline-block;
    height: $arrowSize;
    position: relative;
    width: $arrowSize;
  
    &::after {
      border-bottom-style: solid;
      border-bottom-width: $arrowBorderSize;
      border-right-style: solid;
      border-right-width: $arrowBorderSize;
      content: '';
      display: inline-block;
      height: $arrowSize;
      left: 0;
      position: absolute;
      top: 0;
      width: $arrowSize;
    }
  
    // Triangle shape state
    &.is-triangle::after {
      border-style: solid;
      border-width: 0 0 $arrowSize $arrowSize;
      height: 0;
      width: 0;
    }
    
    // Check shape state
    &.is-check::after {
      width: $arrowSize / 2;
    }
  
    // Arrow Bar
    &.arrow-bar::before {
      bottom: 1px;
      content: '';
      height: $arrowBarHeight;
      position: absolute;
      right: 0;
      transform-origin: bottom;
      width: $arrowBarWidth;
      @include rotate(-45deg);
    }
  
    // States
    &.is-top {
      @include rotate(225deg);
    }
  
    &.is-right {
      @include rotate(315deg);
    }
  
    &.is-bottom {
      @include rotate(45deg);
    }
  
    &.is-left {
      @include rotate(135deg);
    }
  }
  
  @mixin arrowColor($color) {
    &,
    .arrow {
      &::after {
        border-color: $color;
      }
  
      &.is-triangle::after {
        border-color: transparent;
        border-bottom-color: $color;
      }
  
      &::before {
        background-color: $color;
      }
    }
  }
  
  .arrow {
    margin: 0px;
    @include arrow(15px, 2px, 2px, 27px);
    @include arrowColor($default);
  }

  .arrow-btn {
      border-radius: 8px;
      display: inline-block;
      width: 48px;
      height: 48px;
      background-color: #fff;
      border: 1px solid $grey;
      &.left-btn{
        padding-top: 16px;
        padding-left: 12px;
        flex: 0 0 48px;
      }
      &.right-btn{
        flex: 0 0 48px;
        padding-top: 16px;
        padding-left: 20px;
      }
  }

  .empty-arrow-btn {
    width: 48px;
    height: 48px;  
  }
