@import "../../../styles/color.scss";


.synthese-avancement {
    margin-bottom: 32px;
    display: block;
    background-color: $secondary;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    padding: 10px 16px;
    .synthese-title {
        color: $default;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        display: block;
    }
    .synthese-progress-bar {
        padding-top: 8px;
        .MuiLinearProgress-barColorPrimary {
            background-color: $danger!important;
        }
        .MuiLinearProgress-barColorSecondary {
            background-color: $success!important;
        }
        .MuiLinearProgress-colorSecondary {
            background-color: $grey!important;
        }
    }
}
.synthese-tab-header {
    display: flex;
    background-color: $secondary;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    padding: 16px;
    overflow: auto;
    .synthese-title-completion {
        width: calc(100% - 20px);
        display: inline-block;
    }
    .synthese-tab-title {
        color: $default;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        display: inline-block;
        margin-right: 10px;
    }
}
.synthese-tab-completion {
    font-size: 14px;
    display: inline-block;
    line-height: 16px;
    font-weight: 600;
    .tab-progress {
        margin-left: 5px;
        margin-right: 5px;
    }
    .tab-completed {
        color: $successLight;
    }
    .text-completed {
        color: $success;
    }
    .tab-uncompleted {
        color: $dangerLight;
    }
    .text-uncompleted {
        color: $danger;
    }
    .tab-inactive {
        color: $disabledGreyText;
        .text-inactive {
            color: #5A535A;
        }
    }
}
.synthese-tab-groups {
    padding: 16px;
    color: $default;
    font-size: 16px;
    font-weight: 600;
    .group {
        padding: 8px 0;
        .groupe-title {
            margin-right: 10px;
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}
.synthese-criteria {
    text-decoration: line-through;
    margin-left: 5px;
    font-size: 12px;
    color: #A29AA2;
}
.synthese-criteria::before {
    content: "•";
    margin-right: 5px;
}

.synthese-right-arrow {
    display:inline-block;
    line-height: 0px;
    padding: 0;
    color: $primary;
    margin-top: auto;
    margin-bottom: auto;
    svg {
        width: 19px!important;
        height: 19px!important;
}
}
