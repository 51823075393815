.validation-direction-qualite div {
    display: flex;
    width: 100%;
}

.validation-direction-qualite .critera-label {
    margin-left: 50px;
    margin-right: 100px;
    padding: 0px;
    width: 30%;
}

.validation-direction-qualite .critera-box {
    width: 20%;
    margin-left: 20px;
    margin-right: 5px;
}