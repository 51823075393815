.btn-validate-synthese {
    height: 48px;
    border-radius: 8px !important;
    width: calc(100% - 32px);
    margin-top: 10px !important;
    margin-left: 16px !important;
    background-color: #7d1e4f !important;
    color: white !important;
    border: 2px solid #7d1e4f !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
}

.btn-validate-synthese-container{
    margin-top: 10px;
    padding-bottom: 10px;
    background:white;
}


.btn-validate-synthese:disabled {
    background-color: #f1eef1 !important;
    color: #a29aa2 !important;
    border: 2px solid #c0b9c0 !important;
}

