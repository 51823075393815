
.synthese-bloc-photos {
    display: flex;
    flex-wrap: wrap;
    .photo {
        padding: 0px;
        flex: 0 0 33.333333%;
        .libelle-picture {
            text-align:center;
        }
    }
}
@media only screen and (max-width: 1092px) {
    .synthese-bloc-photos {
        .photo {
            margin: auto;
            flex: auto;
        }
    }
}