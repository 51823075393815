@import "./color.scss";

.card {
  width: 952px;
  z-index: 1;
  margin-top: 62px;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
  border-bottom: 1px solid #d6d1d6;
}

.tab-bloc-group {
  width: 50%;
}

.tab-bloc-page {
  width: 100%;
}

.tab-content-group {
  width: 50%;
  padding: 16px;
  background-color: #f8f7f8;
  border-left: 2px solid $tier;
  border-bottom-right-radius: 8px;
}

.tab-page-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.333;
}

.card-content {
  box-shadow: 0 8px 24px 0 #c0b9c04d;
  border-radius: 8px;
  background-color: #ffffff;
}

.display-none {
  display: none;
}

.display-mobile {
  display: none;
}

.card-content-group {
  display: flex;
  width: 100%;
}

.tab-bar-mobile {
  display: none;
}

.tab-save-button-computer {
  display: flex;
  margin-left: 5% !important;
  padding-bottom: 16px;
}

.tab-save-button-mobile {
  display: none !important;
}

.comment-size {
  width: 50%;
}

.comment-dislay-flex {
  display: flex;
}

.parametres-title-card {
  background-color: $secondary;
  border-bottom: solid 1px $grey;
  height: 52px;
}

@media only screen and (max-width: 952px) {
  .comment-size {
    width: 100%;
  }

  .comment-dislay-flex {
    display: unset;
  }

  .card {
    width: 100%;
    z-index: 1;
    padding: 0px !important;
    margin: 0px !important;
  }

  .tab-bloc-group {
    display: none;
  }

  .tab-content-group {
    align-items: stretch;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f8f7f8;
    border-left: 2px solid $tier;
    border-bottom-right-radius: 8px;
  }

  .card-content-group {
    display: grid;
    width: 100%;
  }

  .card-content {
    box-shadow: none;
    padding-top: 0px;
    border-radius: 0px;
    background-color: #ffffff;
    border-top: 1px solid #d6d1d6;
  }

  .commentary-flex{
    height: auto !important;
  }

  .tab-bar-tablette {
    display: none !important;
  }

  .tab-bar-mobile {
    display: inline;
    overflow: auto;
    white-space: nowrap;
    margin: 0px auto;
  }

  .tab-save-button-computer {
    display: none !important;
  }

  .tab-save-button-mobile {
    display: flex !important;
    position: fixed !important;
    left: 0%;
    bottom: 5%;
    z-index: 1000;
  }

  .btn-arrow-menu {
    background-color: transparent;
    border-color: #d6d1d6;
    border-radius: 8px;
    height: 48px;
    width: 48px;
  }

  .btn-left {
    float: left;
  }

  .btn-right {
    float: right;
  }

  .display-mobile {
    display: flex;
  }

  .choice-elt {
    height: 68px !important;

    .MuiButton-label{
      margin: 14px 0 !important;
      
    }
  }

  .parametres-title-card {
    background-color: white !important;
    border-bottom: solid 1px $grey !important;
    height: 52px !important;
    display: flex !important;
  }

  .completion-totale {
    padding-bottom: 0px !important;
  }

  .title-tab {
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    white-space: normal;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.333;
        
  }

  .bandeau-navigation-mobile {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
