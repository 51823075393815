.img-navbar {
  height: 48px;
  width: 150px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  margin-top: 24px;
  cursor: pointer;
}

a {
  display: inline-block;
  width: 100%;
}
