.slider-icon {
  height: 62px;
  width: 32px;
  color: #7d1e4f;
  margin-bottom: 36px;
}

.slider-icon-default {
  height: 24px;
  width: 24px;
}

.slider-icon-value {
  z-index: 5;
  margin-bottom: 66px !important;
  position: absolute;
  color: white;
  font-size: 11px;
  font-weight: bold;
  top: -30px !important;
}

.slider-icon-value-disabled {
  z-index: 5;
  margin-bottom: 66px !important;
  margin-left: 56px !important;
  position: absolute;
  color: #5a535a;
  width: max-content;
  top: -33px !important;
  left: -50px !important;
}

.slider-icon-value-default {
  z-index: 5;
  margin-bottom: 56px !important;
  margin-left: 72px !important;
  position: absolute;
  color: #5a535a;
  width: max-content;
  top: -28px !important;
  left: -70px !important;
}

.slider-not-define {
  color: $disabled !important;
}

.MuiSlider-thumb {
  pointer-events: none !important;
  width: 20px !important;
  height: 20px !important;
}

.critera-label {
  padding-top: 8px;
}

.critera-label-slider {
  margin-bottom: 32px !important;
}

.critera-box {
  border-radius: 14px;
  border: 2px solid;
  padding: 5px;
  padding-right: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.critera-box-align {
  margin-right: 8px;
}

.critera-select-box {
  border-radius: 14px;
  border: 2px solid;
  padding: 5px;
  padding-right: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  color: #5a535a;
}

.critera-period {
  display: flex;
}

.critera-period-middle {
  margin: auto 10px;
}

.critera-date-box,
.critera-date-period-box {
  border-radius: 14px;
  border: 2px solid;
  padding-right: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  color: $primary;
}

.critera-date-box-disabled,
.critera-date-period-box-disabled {
  border: 2px solid $disabled;
  background-color: $disabledGreyBackground;
  color: $disabled;
}

.critera-date-period-box,
.critera-date-box {
  flex: 1;
  input {
    padding: 15px;
  }
}

.critera-date-label {
  font-weight: 600 !important;
  font-size: 16 !important;
  font-style: "normal" !important;
  line-height: "20 (1,25)" !important;
  color: #5a535a !important;
}

.critera-input-text-box {
  border-radius: 8px;
  border: 2px solid;
  padding: 5px 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 48px;
  background-color: #ffffff;
  color: $grey;
}

.comment-critere {
  width: 100%;
  border-color: #d6d1d6;
  border-radius: 8px;
  border-width: 1px;
}

.comment-bloc {
  padding-left: 16px;
  padding-right: 16px;
}

.comment-icon-info {
  vertical-align: bottom;
  margin-right: 8px;
  cursor: pointer;
}

.critera-input-text {
  width: 100%;
}

.select-disabled {
  border-color: $disabled;
}

.select-empty {
  border-color: black;
}

.select-fill {
  border-color: $primary;
}

.critera-select-switch {
  margin-top: 75% !important;
}

.select-popup-indicator {
  color: #7d1e4f;
}

.info-libelle{
  color: #0076A8;
  font-size: 13px;
}

.commentary-flex {
  display: flex;
  height: 70px;
}

.margin-commentary {
  margin-top: 32px;
  height: 32px;
}

.icon-libelle {
  vertical-align: bottom;
  margin-right: 8px;
  cursor: pointer;
}

.MuiAutocomplete-popupIndicatorOpen {
  transform: rotate(90) !important;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}

.MuiInput-underline::after {
  border-bottom: none !important;
}
.critera-picture-card {
  width: 288px;
  height: 192px;
  margin: auto;
  border-radius: 14px !important;
  border: 2px solid $grey;
  box-shadow: none !important;
  overflow: hidden !important;
}

.critera-picture-disabled {
  border-color: $disabledGreyText !important;
  background-color: $disabledGreyBackground !important;
  color: $disabledGreyText !important;
}

.critera-picture-img-miniature {
  border-radius: 10px;
  width: 288px !important;
  height: 168px !important;
  align-items: center;
  display: flex;
}

.critera-picture-img-container {
  border-bottom: 2px solid $grey !important;
  border-radius: 10px;
  width: 288px !important;
  height: 128px !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  img{
    width: auto;
  }
}

.critera-picture-img-container-disabled, .critera-picture-img-container-rapport-envoye {
  border-bottom: 2px solid $disabledGreyText !important;
  border-radius: 10px;
  height: 128px !important;
  align-items: center;
  display: flex;
  img {
    width: 284px;
    object-fit: cover;
  }
}

.critera-picture-img-container-rapport-envoye{ 
  img{  
    height: 188px;
  }
}

.critera-picture-img-container-disabled {
  img{
    width: auto;
  }
}

.critera-picture-img {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
  width: 100% !important;
  height: 100%;
  margin: auto;
  object-fit: contain !important;
}

.critera-picture-img-default {
  margin: auto;
}

.critera-picture-loading {
  margin-left: calc(50% - 20px) !important;
  color: $primary !important;
}

.critera-picture-button {
  margin: auto !important;
}

.critera-picture-inputText {
  width: 288px;
  min-height: 68px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.critera-picture-delete {
  position: absolute !important;
  top: 3px;
  right: 5px;
  z-index: 1;
}

#loading {
  margin-left: 40%;
}
