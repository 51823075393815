@import "../../styles/color.scss";
.helper {
    font-size: 16px;
    display:flex;
    padding-top: 16px;
    margin-top: 16px;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    &-info {
        border-color: #0076A8;
        background-color: #E9F8FF;
        color: #0076A8;
    }
    &-warning {
        border-color: #B85C00;
        background-color: #F7EEE5;
        color: #B85C00;
    }
    &-danger {
        border-color: $danger;
        background-color: #f7e5e5;
        color: $danger;
    }
}

.helper-icon{
    padding-left: 16px;
    padding-right: 8px;
}

.helper-text{
    padding-right: 16px;
    padding-bottom: 16px;
}

.helper-title{
    font-style: bold;
    font-weight: 300;
}

.helper-description{
    font-style: normal;
    font-weight: 400;
}