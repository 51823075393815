.parametres-title-card{
    background-color: $secondary;
    border-bottom: solid 1px $grey;
    height: 52px;
}

.parametre-title-arrow{
    display: inline-block;
    padding-top: 16px;
}

.parametres-title-text{
    font-weight: 600;
    padding-left: 16px;
    padding-top: 16px;
    display:inline-block;
    vertical-align: super;
}

.parametre-recapitulatif-card{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
}

.parametre-recapitulatif-value{
    font-weight: 600;
}

.parametre-recapitulatif-title{
    font-weight: 400;
    padding-top: 16px;
}

.parametre-criteres-card{
    background-color: #F8F7F8;
}

.parametre-criteres-container{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 24px;
}

.parametre-button{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 16px;
    padding-bottom: 16px;
}

.propager-button{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 16px;
}