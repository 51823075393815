@import "../../styles/index.scss";

.rgpd-bloc {
    font-size: 16px;
    width: 650px;
    margin: auto;
    .rgpd-text {
      background-color: #F8F7F8;
      padding: 16px 16px;
        .rgpd-acceptation {
            font-weight : 600;
            line-height: 20px;
      
            .critera-box {
                border-radius: 8px;
            }
            .critera-label {
              display: none;
            }
        }
    }
    .rgpd-button {
      padding: 16px 16px;
      background-color: #fff;
    }
}

.btn-rgpd-next {
    font-weight: 600!important;
    line-height: 1.25!important;
    height: 48px;
    border-radius: 8px!important;
    width: 100%;
    background-color: $primary !important;
    color: white !important;
    border: 2px solid $primary !important;
}

  .btn-rgpd-next:disabled{
    background-color: $disabledGreyBackground !important;
    color: $disabledGreyText !important;
    border: 2px solid $grey !important;
  }

  .rgpd-button-retour-tablette {
    margin-top: 16px;
  }


@media only screen and (max-width: 952px) {
    .rgpd-bloc {
      width: 100%;
      top: 94px;

      .rgpd-terrain{
        .helper{
          margin-top: 100px !important;
        }
      }

    }

    .rgpd-button-retour-tablette {
      display: none;
    }
}