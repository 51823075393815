.radio-btn-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .btn-radio {
    border: 1px solid #202227 !important;
    color: #202227 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
  }
  
  .btn-type-autocontrole {
    width: 50%;
  }
  
  .btn-go-autocontrole {
    height: 48px;
    border-radius: 8px !important;
    width: calc(100% - 32px);
    margin-left: 16px !important;
  }
  
  .active {
    background-color: #7d1e4f !important;
    color: white !important;
    border: 2px solid #7d1e4f !important;
  }
  
  .desactivate {
    background-color: #f1eef1 !important;
    color: #a29aa2 !important;
    border: 2px solid #c0b9c0 !important;
  }
  
  .radio-bloc {
    padding-top: 32px;
    padding-bottom: 32px;
    background: #f8f7f8;
  }
  
  .radio-group-bloc {
    width: 100%;
  }
  
  .choice-elt {
    background-color: white !important;
    height: 48px;
    border: 1px solid #d6d1d6 !important;
    border-radius: 8px !important;
    border-radius: 8px;
    width: 100%;
    margin: 4px 0 !important;
    justify-content: left !important;
    color: #5a535a !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: left !important;
    display: block;
  }
  
  .bloc-field {
    margin-left: 16px;
    margin-top: 16px;
  }
  
  .title-margin {
    margin-top: 32px;
  }
  
  .field-value {
    color: #252225;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  
  .field-label {
    font-size: 16px;
    color: #252225;
    font-weight: 400;
    line-height: 20px;
  }
  
  .status-field {
    min-width:12em;
    height: 32px;
    border-radius: 8px;
    text-align: center;
    padding-top: 4px;
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 32px;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .status-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  
  .status-green {
    border: 1px solid #03805a;
    color: #03805a;
    background: #ebf8f4;
  }
  
  .status-red {
    border: 1px solid #db1428;
    color: #db1428;
    background: #ffeff1
  }
  
  .status-brown {
    border: 1px solid #b85c00;
    color: #b85c00;
    background: #f7eee5;
  }
  
  .status-black {
    border: 1px solid #5a535a;
    color: #5a535a;
    background: #f1eef1;
  }
  
  .status-bleu {
    border: 1px solid #0076a8;
    color: #0076a8;
    background: #e9f8ff;
  }
  
  .status-with-date {
    height: 48px !important
  }
  
  .seach-form {
    padding: 8px 0;
    display: flex;
    align-items: center;
    border: 1px solid #252225;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 8px !important;
    box-shadow: none !important;
  }
  
  .iconSearch{
    filter: opacity(54%) brightness(50%) saturate(50%);
  }
  
  .search-bar{
    padding : 0 8px 0 16px !important;
  }