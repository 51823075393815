@import "./color.scss";
@import "./mobile.scss";
@import "./tabInfos.scss";
@import "./customGroupMenu.scss";
@import "./customTabMenu.scss";
@import "./critere.scss";
@import "./ParametreAutocontrole.scss";
@import "./tabSynthese.scss";
@import "./arrow.scss";

@font-face {
  font-family: "Avenir Next";
  src: url("../font/AvenirNextLTPro-Regular.otf") format("opentype");
}

* {
  font-family: "Avenir Next", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #ffffff !important;
  position: relative;
  padding-bottom: 64px;
}

html {
  height: 100%;
}

.text-dark {
  color: $default;
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ffffff;
  height: 96px;
}

.MuiAlert-message {
  // pour gérer les passages à la ligne dans les snackbars component
  white-space: pre-wrap;
}

.txt-center {
  text-align: center;
  margin: auto 0;
  vertical-align: middle;
  overflow: auto;
  padding: 0 16px;
}

.app-img-background {
  height: 230px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.elt-float-right {
  float: right;
}

.flex-row {
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: left !important;
  padding-right: 10px;
  text-align: left;
}

.MuiLinearProgress-barColorPrimary {
  background-color: $primary !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: $grey !important;
}

.group-autocontrole {
  margin-bottom: 16px;
  .helper{
    margin-top: 16px !important;
  }
}

.icon-info {
  height: 32px;
  width: 32px;
  vertical-align: middle;
  margin-right: 5px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.alerte-info-title {
  color: #0076a8;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.buttonDialog{
  &:hover{
    background-color: #e5d2dc !important;
    color: #252225;
  }
}

.widthInherit{
  width: inherit;
}

.info-alerte {
  border: 1px solid #0076a8;
  font-size: 16px;
  font-weight: 400;
  color: #0076a8 !important;
}

.btn-close-modal {
  font-weight: 600 !important;
  float: right;
}

.alert-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #0076a8;
  vertical-align: top;
  text-align: justify;
}

.date-expired {
  color: $danger !important;
}

.date-not-expired {
  color: #202227 !important;
}

.date-near-expired{
  color: #C8823B !important;
}

.boldTitle h2{
  font-weight: bold;
}

.centerImageInformation{
  display: flex;
  justify-content: center;
  img{
    width: 60px;
  }
}

.center-div {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 20em;
    height: auto;
  }
}

.error-div {
  text-align: center;
  height: 6em;
  font-weight: bold;
  font-size: 22px;
  color: red;
}

.version {
  position: absolute;
  bottom: 5px;
  right: 16px;
  font-size: 12px;
  color: #5e6c84;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.bg-white {
  background-color: #fff;
}
.margin-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
