$default: #252225;
$disabled: #c0b9c0;
$primary: #7d1e4f;
$secondary: #f2e8ed;
$tier:#C4CCE5;
$grey: #d6d1d6;
$lightGrey:#F8F7F8;
$disabledGreyText: #A29AA2;
$disabledGreyBackground :#F1EEF1;
$selectedTab: #E5D2DC;
$success: #03805A;
$successLight: #6BDCBA;
$danger: #DB1428;
$dangerLight: #F77F8B;

