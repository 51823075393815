.synthese-bloc-commentaires {
    display: flex;
    flex-wrap: wrap;
    .commentaire {
        padding: 0 16px;
        flex: 0 0 50%;
    }
    .commentaire-cmq {
        padding: 0 16px;
        flex: auto;
    }
}

@media only screen and (max-width: 952px) {
    .synthese-bloc-commentaires {
        .commentaire {
            flex: 0 0 100%;
        }
    }
}