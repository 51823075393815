@import "../../styles/color.scss";

.mail-title-container{
    height: 52px;
    background-color: $secondary;
    border-bottom: 1px solid $grey;
}

.mail-title-arrow{
    display: inline-block;
    padding-top: 16px;
}

.mail-title-text{
    font-size: 16px;
    font-weight: 600!important;
    padding-top: 16px;
    margin-left: 16px;
    display:inline-block;
    vertical-align: super;
}

.mail-infos-container{
    background-color: $lightGrey;
    border-bottom: 1px solid $grey;
}

.mail-infos-helper{
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 90%;
}

.helper{
    margin-top: 0px !important;
}

.mail-container{
    margin-bottom: 16px;
    .mail-text-input-container{
        border-bottom: 1px solid $grey !important;

        .body-mail-rapport{
            display: block;
            padding: 16px 16px;
            
            .commentaire-cmq-mail{
                padding-left: 0 !important;
            }

            textarea{
                padding-right: 16px !important;
                border-top: $primary 2px dashed;
                border-bottom: $primary 2px dashed;
                padding: 0.5vw 0.1vw;
                min-height: 10em !important;
            }
        }
    }

    .MuiInputBase-root {
        align-items: baseline !important;
    }

    .MuiTypography-colorTextSecondary{
        font-size: 16px !important;
        font-weight: 600!important;
        color: $default !important;
    }

    .MuiFormControl-fullWidth{
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 16px !important;
    }

    .MuiInputBase-root.Mui-disabled {
        color: $default !important;
    }
}

.send-mail-button{
    padding-bottom: 16px;
}